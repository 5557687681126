
import {
  Component,
  Vue
} from 'vue-property-decorator'
import {
  ToastModule
} from '@/store/modules/ToastModule'
import EmployeeService from '../services/employee-service'
import MemberTypes from '@/data/member-types.json'
import Countries from '@/data/countries.json'
import {
  mask
} from 'vue-the-mask'
import {
  AuthModule
} from '@/store/modules/AuthModule'
import {
  Validations
} from 'vuelidate-property-decorators'
import {
  Vuelidate
} from 'vuelidate'
import {
  email,
  alpha,
  alphaNum,
  numeric,
  required,
  minLength,
  maxLength
} from 'vuelidate/lib/validators'

  @Component({
    name: 'ClientRegister',
    directives: {
      mask
    }
  })
export default class ClientRegister extends Vue {
    protected tabIndex = 1
    protected genderTypes = ['Male', 'Female']
    protected types = ['Casual', 'Permanent']
    protected categories = MemberTypes
    protected countries = Countries
    protected nationalities = ['Sinhala', 'Tamil', 'Muslim', 'Burgher', 'Malay']
    protected banks = ['Sampath Bank', 'Bank Of Ceylon']
    protected branches = ['Nugegoda', 'Galle']
    protected addresses: any = []
    private statuses: any = [{
      value: 1,
      text: 'Active'
    },
    {
      value: 0,
      text: 'Inactive'
    }
    ]

    protected employee: any = {
      id: null,
      type: null,
      category: null,
      name: null,
      email: null,
      designation: null,
      nic_number: null,
      sex: null,
      nationality: null,
      date_of_birth: null,
      mobile_number: null,
      home_number: null,
      add_to_list: false,
      status: false,
      created_user: null,
      modified_user: null
    }

    protected address: any = {
      street: null,
      city: null,
      state: null,
      postal_code: null,
      country: null,
      source_type: null,
      source_id: null,
      created_user: null
    }

    protected bank: any = {
      account_name: null,
      account_number: null,
      bank_name: null,
      branch_name: null,
      swift_code: null,
      source_type: null,
      source_id: null,
      created_user: null
    }

    $v: Vuelidate

    @Validations()
    validations = {
      employee: {
        name: {
          required,
          minLength: minLength(5)
        },
        nic_number: {
          required,
          alphaNum,
          minLength: minLength(7)
        },
        sex: {
          required
        },
        type: {
          required
        },
        category: {
          required
        },
        designation: {
          minLength: minLength(5)
        },
        email: {
          email
        },
        mobile_number: {
          numeric,
          minLength: minLength(10)
        },
        home_number: {
          numeric,
          minLength: minLength(10)
        }
      }
    }

    public open () {
      (this.$refs.EmployeeModal as any).show()
    }

    public close () {
      (this.$refs.EmployeeModal as any).hide()
      this.reset(this.employee)
      this.reset(this.address)
      this.reset(this.bank)
    }

    protected isNumber ($event: any) {
      const charCode = ($event.keyCode ? $event.keyCode : $event.which)
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        $event.preventDefault()
      } else {
        return true
      }
    }

    public populateEmployee (id) {
      EmployeeService.getEmployee(id).then((response) => {
        this.employee = {
          id: response.data.employee.id,
          type: response.data.employee.type,
          category: response.data.employee.category,
          name: response.data.employee.name,
          email: response.data.employee.email,
          designation: response.data.employee.designation,
          nic_number: response.data.employee.nic_number,
          sex: response.data.employee.sex,
          nationality: response.data.employee.nationality,
          date_of_birth: response.data.employee.date_of_birth,
          mobile_number: response.data.employee.mobile_number,
          home_number: response.data.employee.home_number,
          add_to_list: response.data.participant,
          status: response.data.employee.status === 1
        }
        this.address = {
          id: response.data.address.id,
          street: response.data.address.street,
          city: response.data.address.city,
          state: response.data.address.state,
          postal_code: response.data.address.postal_code,
          country: response.data.address.country
          // source_type: response.data.address.source_type,
          // source_id: response.data.address.source_id
        }
        this.bank = {
          id: response.data.bank.id,
          account_name: response.data.bank.account_name,
          account_number: response.data.bank.account_number,
          bank_name: response.data.bank.bank_name,
          branch_name: response.data.bank.branch_name,
          swift_code: response.data.bank.swift_code
          // source_type: response.data.bank.source_type,
          // source_id: response.data.bank.source_id
        }
      })
    }

    public saveBasic () {
      this.employee.created_user = AuthModule.user.fullname
      this.employee.modified_user = AuthModule.user.fullname
      this.$v.employee.$touch()
      if (this.$v.employee.$invalid) return false
      EmployeeService.saveBasic(this.employee).then((response) => {
        ToastModule.message(response.data.message)
        this.employee.id = response.data.id;
        (this.$parent as any).populateEmployees()
      }).catch(error => {
        console.log(error)
      })
    }

    protected saveAddress () {
      this.address.created_user = AuthModule.user.fullname
      this.address.modified_user = AuthModule.user.fullname
      this.address.source_type = 'Employee'
      this.address.source_id = this.employee.id
      EmployeeService.saveAddress(this.address).then((response) => {
        ToastModule.message(response.data.message)
        this.reset(this.address)
      }).catch(error => {
        ToastModule.message(error.response.data.message)
      })
    }

    protected saveBankDetails () {
      this.bank.created_user = AuthModule.user.fullname
      this.bank.modified_user = AuthModule.user.fullname
      this.bank.source_type = 'Employee'
      this.bank.source_id = this.employee.id
      EmployeeService.saveBankDetails(this.bank).then((response) => {
        ToastModule.message(response.data.message)
        this.reset(this.bank)
      }).catch(error => {
        ToastModule.message(error.response.data.message)
      })
    }

    private clear () {
      this.reset(this.employee)
    }

    private reset (obj: any) {
      for (const key in obj) {
        obj[key] = null
      }
    }
}

