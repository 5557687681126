
import {
  Component,
  Vue
} from 'vue-property-decorator'
import {
  AuthModule
} from '@/store/modules/AuthModule'
import EmployeeService from './services/employee-service'
import EmployeeModal from './components/EmployeeModal.vue'
import {
  ToastModule
} from '@/store/modules/ToastModule'
import {
  BreadcrumbModule
} from '@/store/modules/BreadcrumbModule'
  @Component({
    name: 'Employee',
    components: {
      EmployeeModal
    }
  })
export default class Employee extends Vue {
    protected isBusy = false
    protected isEdit = false
    protected buttonTabString = null
    protected searchTable = null
    protected items: any = []
    protected fields = [{
      key: '#',
      label: '#',
      thStyle: {
        width: '4%'
      },
      class: 'text-right'
    },
    {
      key: 'name',
      label: 'Name',
      thStyle: {
        width: '15%'
      }
    },
    {
      key: 'nic_number',
      label: 'NIC No.',
      thStyle: {
        width: '10%'
      }
    },
    {
      key: 'sex',
      label: 'Gender',
      thStyle: {
        width: '10%'
      }
    },
    {
      key: 'designation',
      label: 'Designation',
      thStyle: {
        width: '10%'
      }
    },
    {
      key: 'mobile_number',
      label: 'Mobile Number',
      thStyle: {
        width: '10%'
      }
    },
    {
      key: 'created_user',
      label: 'Created User',
      thStyle: {
        width: '10%'
      }
    },
    {
      key: 'version',
      class: 'text-center',
      thStyle: {
        width: '6%'
      }
    },
    {
      key: 'action',
      label: '',
      thStyle: {
        width: '10%'
      },
      class: 'text-center'
    }
    ]

    private genderTypes = ['Male', 'Female']

    mounted () {
      this.items = this.items.map(items => ({
        ...items,
        isEdit: true
      }))
    }

    created () {
      this.boot()
    }

    private boot () {
      BreadcrumbModule.setBreadcrumb(this.$route)
      this.populateEmployees()
    }

    public openModal () {
      (this.$refs.EmployeeInfoModal as any).open()
    }

    public closeModal () {
      (this.$refs.EmployeeInfoModal as any).close()
    }

    public edit (id: number) {
      (this.$refs.EmployeeInfoModal as any).populateEmployee(id);
      (this.$refs.EmployeeInfoModal as any).open()
    }

    protected async populateEmployees () {
      this.isBusy = true
      const response = await EmployeeService.getAllEmployees()
      this.isBusy = false
      this.items = response.data.map(item => ({
        ...item,
        isEdit: false,
        modified_user: AuthModule.user.fullname
      }))
    }

    protected isNumber ($event: any) {
      const charCode = ($event.keyCode ? $event.keyCode : $event.which)
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        $event.preventDefault()
      } else {
        return true
      }
    }

    protected editRowHandler (data: any) {
      this.items[data.index].isEdit = !this.items[data.index].isEdit
    }

    protected update (item: any) {
      EmployeeService.updateStaffMember(item).then((response: any) => {
        item.isEdit = false
        ToastModule.message(response.data.message)
      }).catch(error => {
        ToastModule.message(error.response.data.message)
      })
    }

    protected async print () {
      await this.$htmlToPaper('printList')
    }
}

